export enum Campaign {
  TFTF = 3,
  OVI = 4,
  PF = 5,
  SARTHAK = 6,
}

export const campaignFromNumber = (campaignId: number): Campaign => {
  switch (campaignId) {
    case 3:
      return Campaign.TFTF;
    case 4:
      return Campaign.OVI;
    case 5:
      return Campaign.PF;
    case 6:
      return Campaign.SARTHAK;
    default:
      throw new Error(`Unknown campaign: ${campaignId}`);
  }
};

export const colors: Record<Campaign, string> = {
  [Campaign.TFTF]: "#A2FFD2",
  [Campaign.OVI]: "#61E5FF",
  [Campaign.PF]: "#61E5FF",
  [Campaign.SARTHAK]: "#FFE617",
};

export const emojis: Record<Campaign, string> = {
  [Campaign.TFTF]: "/images/emojis/tree.png",
  [Campaign.OVI]: "/images/emojis/ocean.png",
  [Campaign.PF]: "/images/emojis/ocean.png",
  [Campaign.SARTHAK]: "/images/emojis/book.png",
};
