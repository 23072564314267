<template>
  <svg
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.716049 3.05899C1.20988 2.1236 1.90123 1.39045 2.83951 0.834269C3.75309 0.278089 4.83951 0 6.12346 0C7.30864 0 8.34568 0.202247 9.23457 0.606741C10.1235 1.01124 10.7901 1.5927 11.284 2.30056C11.7531 3.03371 12 3.86798 12 4.77809C12 5.89045 11.7778 6.75 11.3333 7.38202C10.8889 8.01404 10.2716 8.62079 9.4321 9.20225C8.69136 9.70786 8.14815 10.1629 7.85185 10.5169C7.55555 10.8961 7.40741 11.3258 7.40741 11.8315V12.1601H4.14815V11.4522C4.14815 10.4916 4.34568 9.75843 4.76543 9.20225C5.18518 8.67135 5.85185 8.14045 6.76543 7.58427C7.33333 7.23034 7.75309 6.85112 8.02469 6.44663C8.2716 6.06742 8.41975 5.61236 8.41975 5.10674C8.41975 4.5 8.19753 4.01966 7.80247 3.64045C7.38272 3.26124 6.83951 3.05899 6.14815 3.05899C5.35802 3.05899 4.71605 3.36236 4.24691 3.91854C3.75309 4.47472 3.53086 5.23315 3.53086 6.14326H0C0 5.0309 0.222222 3.99438 0.716049 3.05899ZM4.39506 14.5112C4.79012 14.132 5.28395 13.9298 5.87654 13.9298C6.46914 13.9298 6.93827 14.132 7.33333 14.5112C7.72839 14.8905 7.92593 15.3708 7.92593 15.9522C7.92593 16.559 7.72839 17.0393 7.33333 17.4185C6.93827 17.823 6.44444 18 5.87654 18C5.28395 18 4.81481 17.823 4.41975 17.4185C4.02469 17.0393 3.82716 16.559 3.82716 15.9522C3.82716 15.3708 4 14.8905 4.39506 14.5112Z"
      fill="currentColor"
    />
  </svg>
</template>
