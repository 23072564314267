<template>
  <div class="tracking-[-1%] text-navy">
    <div class="flex items-start gap-1.5">
      <div class="flex-1 space-y-4">
        <h5 class="font-Kontesa text-[21px] font-medium uppercase leading-none">
          {{ campaign?.organizationName }}
        </h5>
        <h6 class="font-Aeonik text-[15px] opacity-50">
          {{ campaign?.title }}
        </h6>
      </div>

      <div
        class="relative h-[28px] w-[95px] shrink-0 rounded-md border border-navy bg-gradient-to-b from-[#E7DBFF] to-white shadow-[0px_6px] shadow-navy"
      >
        <NuxtLink
          :href="`https://campaigns.dollardonationclub.com/reports/${campaign?.slug}`"
          target="_blank"
          class="rounded-md"
        >
          <img
            class="absolute left-0 h-[54px] w-[54px] -translate-y-4"
            src="/images/logo-icon.png"
          />
          <div class="absolute right-1 translate-y-0.5">
            <span
              class="font-Kontesa text-[21px] font-semibold leading-none tracking-tight"
            >
              {{ campaign?.impactScore }}</span
            ><span
              class="font-Aeonik text-[12px] font-medium leading-none tracking-tight"
              >%</span
            >
          </div>
        </NuxtLink>
      </div>
    </div>

    <div class="my-3 w-full border-t border-dashed border-navy" />

    <div class="flex items-center gap-4">
      <div class="flex shrink-0 items-center space-x-3">
        <span
          :style="{ writingMode: 'vertical-lr' }"
          class="rotate-180 font-Kontesa text-[12px] font-medium leading-none"
        >
          Expert review
        </span>
        <img
          class="h-[72px] w-[72px] rounded-xl border-2 border-navy object-cover"
          :src="campaign?.featuredReview.photo"
          alt="Expert picture"
        />
      </div>

      <div class="flex-1 space-y-2">
        <div class="font-Kontesa text-[28px] font-medium leading-none">
          {{ campaign?.featuredReview.name }}
        </div>
        <div class="font-Aeonik text-[15px] leading-tight opacity-50">
          {{ campaign?.featuredReview.title }}
        </div>
      </div>
    </div>

    <div class="my-3 w-full border-t border-dashed border-navy" />

    <p class="font-Aeonik text-sm font-medium">
      {{ campaign?.featuredReview.quote }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { useCampaign } from "~/domains/campaigns/composables";
import type { Campaign } from "~/lib/campaigns";

const props = defineProps<{
  campaignId: Campaign;
}>();

const { campaign } = useCampaign(() => props.campaignId);
</script>
